/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
@import "~primeng/resources/primeng.min.css";
@import "~primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "~primeicons/primeicons.css";
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
@import "~admin-lte/dist/css/adminlte.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~ngx-toastr/toastr";

// body {
//     width: 100vw;
//     height: 100vh;
// }

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.user-menu > .nav-link {
  cursor: pointer;
}

.user-menu > .nav-link:after {
  content: none;
}

.user-menu > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
  height: 175px;
  padding: 10px;
  text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
  z-index: 5;
  height: 90px;
  width: 90px;
  border: 3px solid;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
  z-index: 5;
  font-size: 17px;
  margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
  display: block;
  font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
  border-bottom: 1px solid #495057;
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
  display: block;
  clear: both;
  content: "";
}

@media (min-width: 576px) {
  .user-menu > .dropdown-menu > .user-body a {
    background: #ffffff !important;
    color: #495057 !important;
  }
}

.user-menu > .dropdown-menu > .user-footer {
  background-color: #f8f9fa;
  padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
  display: block;
  clear: both;
  content: "";
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
  color: #6c757d;
}

@media (min-width: 576px) {
  .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f8f9fa;
  }
}

.user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2.1rem;
  margin-right: 10px;
  margin-top: -2px;
  width: 2.1rem;
}

@media (min-width: 576px) {
  .user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-right: 0.4rem;
    margin-top: -8px;
  }
}

.user-menu .user-image {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0;
  margin-left: -8px;
}

.content {
  padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for  /close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}

// .p-paginator {
//     justify-content: right !important;
// }

/* Add application styles & imports to this file! */
body {
  // font-family: Arial, Helvetica, sans-serif;
  //  font-family: system-ui;
  //  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

.p-component {
  // font-family:   system-ui;
  // font-size: 1rem;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
}

.container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.quote {
  color: #8cc540;
  font-style: italic;
}

.redText {
  color: Red;
  font-size: 12px;
  font-family: Arial;
}

h1.titleText {
  color: #69a8cf;
  text-align: left;
  margin-top: 40px;
  font-weight: normal;
}

.register-box {
  width: 450px;
}

.login-box {
  width: 100%;
}

.p-dropdown {
  width: 100%;
}

:host ::ng-deep .p-dropdown {
  width: 14rem;
}

.p-inputgroup-addon {
  height: 38px;
}

#insertVideo-editor1 {
  display: none !important;
}

.alert {
  position: relative;
  padding: 0px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.card-title {
  float: left;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

.p-datatable {
  position: relative;

  color: white;
}

.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand).sidebar-focused,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover {
  width: 4.6rem;
}

.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand).sidebar-focused
  .brand-text,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand).sidebar-focused
  .logo-xl,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand).sidebar-focused
  .sidebar
  .nav-sidebar
  .nav-link
  p,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand).sidebar-focused
  .user-panel
  > .info,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand):hover
  .brand-text,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand):hover
  .logo-xl,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand):hover
  .sidebar
  .nav-sidebar
  .nav-link
  p,
.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand):hover
  .user-panel
  > .info {
  visibility: hidden;
}

.nav-sidebar > .nav-item .nav-icon.fa,
.nav-sidebar > .nav-item .nav-icon.fab,
.nav-sidebar > .nav-item .nav-icon.fad,
.nav-sidebar > .nav-item .nav-icon.fal,
.nav-sidebar > .nav-item .nav-icon.far,
.nav-sidebar > .nav-item .nav-icon.fas,
.nav-sidebar > .nav-item .nav-icon.ion,
.nav-sidebar > .nav-item .nav-icon.svg-inline--fa {
  font-size: 1.2rem;
}

.text-12 {
  font-size: 12px !important;
}

.text-12 {
  font-size: 12px !important;
}

.login-box-msg.login-title {
  padding: 0px 0px 20px 0px;
  font-weight: 700;
  font-size: 20px;
}

.profile-center .avatar-container {
  margin-bottom: 20px;
}

.p-table {
  padding: 0.5rem 1rem !important;
}

.table-padding .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem !important;
}

.table-padding .p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem !important;
}

.table-padding .p-datatable .p-datatable-thead > tr > th i {
  font-size: 12px !important;
}

.table-padding .p-datatable .p-datatable-tbody > tr > td .p-element {
  height: 26px;
  width: 26px;
}

.table-padding .p-datatable .p-datatable-tbody > tr > td .p-element .pi {
  font-size: 12px;
}

.nav-pills .nav-link {
  color: #0f0f0f;
}

.sidebar-color a {
  color: #000;
}

.p-accordion-tab {
  padding-bottom: 10px;
}

//new css
.p-datatable .p-datatable-footer {
  background: #fff !important;
  border-width: 3px 0 1px 0 ;
}

.p-button-black {
  border: 1px solid #e72744 !important;
}

.p-button-black .pi {
  color: #e72744 !important;
}

.table-width .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e72744;
  border-color: #e72744;
  color: #ffffff !important;
}

.table-width .p-paginator .p-paginator-current {
  color: black;
}

.table-width .p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: black;
}

.sidebar-collapse .martsup-logo {
  display: none;
}

.store-list-popup .p-dialog-footer button {
  background-color: #e72744 !important;
  border: 1px solid #e72744;
}

.store-list-popup .p-button:focus {
  box-shadow: none;
}

.store-list-popup {
  width: 50vw;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .p-dialog {
    width: 90%;
  }

  .store-list-popup .p-dialog-footer button {
    background-color: #14213d !important;
    border: 1px solid #14213d;
  }

  .store-list-popup .p-button:focus {
    box-shadow: none;
  }

  // .mobileview-addstore-btn {
  //   justify-content: center;
  // }
  .profile-center .avatar-container {
    margin: 0 auto;
  }
  .profile-center {
    margin-left: 23% !important;
  }
}

::placeholder {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* color: rgba(0, 0, 0, 0.12) !important; */
  color: rgba(29, 31, 32, 0.3) !important;
}

.select-email-text {
  font-weight: 600;
  font-size: 9px;
  line-height: 15px;
  color: rgba(29, 31, 32, 0.3) !important;
  right: 15px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* color: rgba(0, 0, 0, 0.12) !important; */
  color: rgba(29, 31, 32, 0.3) !important;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    color: #e72744 !important;
    background: #fff !important;
  }
}

.menu-hover .nav-pills .nav-link:not(.active):hover {
  color: #e72744 !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Open Sans", sans-serif !important;
}

.p-datatable-header {
  background-color: white !important;
}

.category-comp-padd .p-datatable-header {
  padding-bottom: 0px;
}

// .ngx-toastr {
//   @media screen and (min-width: 320px) and (max-width: 768px) {
//   }
// }

.angular-editor-placeholder {
  font-size: 0.75rem;
  font-weight: 100;
}

.sidebar-collapse.sidebar-mini
  .main-sidebar:hover:not(.sidebar-no-expand)
  .nav-link {
  width: 3.6rem !important;
}

.angular-editor-placeholder {
  position: absolute;
  top: 0;
  padding: 0.5rem 0.8rem 1rem 0.9rem;
  color: rgba(29, 31, 32, 0.3) !important;
  opacity: 1 !important;
  font-weight: 600 !important;
}
.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover {
  background: #14213d !important;
  color: #ffff !important;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #545b62;
}
.input-choose span {
  display: none;
}
.product-list-table .p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #4947475e;
}

.nav-sidebar .nav-link p {
  //display: inline; fix sidebar jumping issue sks
  margin-left: 0px;
  white-space: nowrap;
  font-weight: 600;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #14213d;
}
.eyeicon {
  position: absolute;
  right: 15px;
  top: 8px;
  cursor: pointer;
}
.sidebar_icon .nav-sidebar .nav-link img {
  width: 20px;
}
.sidebar_icon.elevation-4 {
  box-shadow: 0px 4px 57px 2px rgba(0, 0, 0, 0.15) !important;
}
.nav-pills .nav-link.active img {
  filter: brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(5768%)
    hue-rotate(338deg) brightness(93%) contrast(93%);
}
.menu-hover .nav-pills .nav-link:not(.active):hover img {
  filter: brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(5768%)
    hue-rotate(338deg) brightness(93%) contrast(93%);
}
.login-page,
.register-page {
  background: transparent;
}
.form-control:focus {
  box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.09);
  border: 1px solid rgba(0, 0, 0, 0.22);
}
.edit-button {
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-datatable .p-datatable-header {
  border-width: 0px !important;
  padding: 0rem 0rem !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 38px !important;
}
.width-100 {
  width: 100% !important;
}
.height38 {
  height: 38px;
}
.mat-stepper-horizontal {
  margin-top: 8px;
}

.mat-form-field {
  margin-top: 16px;
}

span.tablinks.active {
  border-bottom: 1px solid #ffffff;
}

.highlight_sideMenu {
  color: #e72744 !important;
  background: #fff !important;
}
.btn-colour {
  background-color: #e72744;
  color: white !important;
  padding: 6px 40px;
  font-weight: 600;
}
.btn-colour-cancel {
  color: #14213d;
  border-color: #e72744;
  padding: 6px 32px;
  font-weight: 600;
  margin-left: 15px;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
  margin-top: -4px;
}

// password eye icon for edge
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.p-button.p-button-icon-only {
  // width: 4rem;
  // padding: 0.4rem 0;
  height: 2.3rem;
}
.p-calendar .p-inputtext {
  // width: 15rem !important;
  height: 2.3rem;
}
.p-button {
  color: #ffffff;
  background: #e72744;
  border: 1px solid #e72744;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-datepicker table td {
  padding: 0.1rem !important;
}
input[type="checkbox"] {
  width: 15px;
  height: 15px;
  accent-color: #14213d;
}
.product-checkbox {
  margin-left: 50px;
}

// new css by sks 30 11 2022
.marginleft-10px{
  margin-left: 10px;
}

.mobSearch::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-size:16px;
}

.mobSearch::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size:16px;
}
.mobSearch:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size:16px;
}
.mobSearch::-webkit-input-placeholder { /* Most modern browsers support this now. */
  font-size:16px;
}
.padding-rem-75{
padding: .75rem !important;
}
@media (max-width: 575.98px) {
  .toast-top-right {
    top: unset;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-container.toast-top-right .toast {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {

  .add-store-btn {
    background: #14213D;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    width: 99%;
    height: 46px;
    border:none;
  }

  .refresh-button {
    color: #14213d;
    background: #F9FBFF;
    border: 1px solid #14213d;
    border-radius: 4px;
    font-weight: 600;
    width: 99%;
    height: 46px;
    float: right;
  }
.mobile-btn-save{
  background: #14213D;
  border-radius: 4px;
  color: white;
  padding: 9px 15px;
  width: 50%;
  height: 46px;
  font-size: 18px;
  float: right;
}
.mobile-btn-save-cancel{
  width: 45%;
  height: 46px;
  color:#14213d;
  background: #F6F6FC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  border: none;
  float: left;
  border: 1px solid #14213d;
}

.p-button:enabled{
  color: #ffffff;
  background: #14213d !important;
  border: 1px solid #14213d;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  border-color: #14213D !important;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.02rem rgb(38 143 255 / 50%) !important;
}
.intl-tel-input .iti__country-list {
  position: absolute !important;
  z-index: 2 !important;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
  width: 264px !important;
}
}

.required {
  color: #e72744;
}
.h-18{
  height: 18px;
  margin-bottom: 4px;
}
.Buttontextsize{
  font-size: 18px;
}
.text-9{
  font-size: 9px ;
}
.text-13{
  font-size: 13px ;
}
input[type='radio'] {
  accent-color: #14213d;
}

.active-font-weight{
  font-weight: 700;
}
.text-center{
  justify-content: center;
}

.new-user-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.createaccount-text {
  color: #e72744 !important;
  font-weight: 800 ;
  font-size: 1.08rem;
}

.ngx-toastr {
  @media (max-width: 768px) {
    width: 100% !important;  //  Specify toastr-dialog width only on small devices

  }
}

// .p-button:enabled:hover {
//   background: #14213d;
//   color: #ffffff;
//   border-color: #14213d;

// }


// Report CSs
.p-button:enabled:hover {
  background: #e72744 ;
  color: #ffffff ;
  border-color: #e72744 ;
}

.p-datepicker table td>span.p-highlight {
  color: #ffffff;
  background: #e72744;
  height: 22px;
  width: 22px;

}

.p-datepicker table td>span {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 4px;
  transition: box-shadow 0.15s;
  border: 1px solid transparent;

}

.p-datepicker table {
  font-size: 12px
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,

.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 20px;
  height: 20px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0rem;
}

.p-link {
  font-size: 0.9rem;
}

// mobile report css start
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background:white !important;
  border-color:none !important;
  color: #212529;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem 1rem !important;
  border: none;
  background: white !important;

}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: white !important;
  border-color: white !important;
  color: #212529;
}
.p-accordion-tab {
   padding-bottom: 0px !important;
}
@media screen and (max-width: 769px){
.p-datepicker table th, .p-datepicker table td {
    padding: 0.5rem;
}
.p-calendar {
  position: relative;
  display: inline-flex;
  max-width: 100%;
  width: 100%;
}
.p-accordion-toggle-icon.pi-chevron-down:before {
  content: url("./assets/svgimages/expand.svg");
}
.p-accordion-toggle-icon.pi-chevron-right:before {

  content: url("./assets/svgimages/collapse.svg");

}
.p-datepicker .p-datepicker-header {

  padding: 0rem 1rem !important;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: white !important;
}
.p-accordion-tab {
  padding-bottom: 0px !important;
  border: 1px solid #007bff !important;
  border-radius: 5px;
}
.p-accordion-header-text
{
  font-weight: 600;
  color: #212529;
font-size: 14px;
margin-left: 5px;
}

}
.p-accordion .p-accordion-content {
  padding: 1rem;
padding-top: 0;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.02rem rgb(38 143 255 / 50%); 
  border-color: #007bff; 
}
.p-button:focus {
  box-shadow: 0 0 0 0.02rem rgb(38 143 255 / 50%); 
}

.table-padding .p-datatable .p-datatable-tbody > tr > td .p-element .p-dialog {
  height:70% !important;
  width: 22% !important;

  }
  .chart{
    padding: 10px;
  }


.p-dialog-style-class {
  width: 60%;
  height: 50%;

}
.product-list-table .p-datatable-scrollable .p-datatable-tbody>tr>.email-id {
	/* essential */
  display: inline;
  width: 100px;
  white-space: nowrap; 
  overflow: hidden  !important;
  text-overflow: ellipsis; 

   
}
.product-list-table .p-datatable-scrollable .p-datatable-tbody>tr>.tablename {
	/* essential */
  display: inline;
  width: 70px;
  white-space: nowrap; 
  overflow: hidden  !important;
  text-overflow: ellipsis; 

   
}
.p-datatable-scrollable .p-datatable-tbody>tr>.description
{
	/* essential */
  display: inline;
  width: 100px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 

   
}
#sellingPrice::-webkit-inner-spin-button,

#sellingPrice::-webkit-outer-spin-button {
 -webkit-appearance: none;
  margin: 0;

}

  .iti-mobile .iti--container {
    bottom: 0px;
    top:7%;
   
    position: absolute;
    width: 334px;
    min-height: 500px;
}
.iti {
  width: 100% !important;
  }

  .iti__country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #ccc;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 334px;
}

@media screen and (max-width: 769px){
.iti__country-list  {
   
    position: absolute !important;
    z-index: 2 !important;
    list-style: none;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #ccc;
    white-space: nowrap;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 83% !important;
   // min-height: 50% !important;
    bottom: 13% ;
    top: 11%;
  
}
.ngx-pagination .current {
 
  background: #14213D !important;

}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-right: 50px;
}
}
@media (max-width: 576px){
  .login-box, .register-box {
    margin-top: 0rem !important;
}
}
